import classnames from "classnames";
import FreeToAir from "components/Icons/FreeToAir";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import { LinkDuo } from "components/LinkDuo";
import LogoVod from "components/LogoVod";
import ProgressBar from "components/ProgressBar";
import ThumbnailInfos from "components/ThumbnailInfos";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { getRealSlug } from "helpers/url";
import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";
import { TucanoModels } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

export default class ThumbnailPoster extends PureComponent {
  state = {
    hover: false,
    leavingModal: false,
    item: null,
    showModal: false,
  };
  async componentDidMount() {
    if (consts.displayVodData) {
      const { asset } = this.props;
      let metadata;
      if (asset instanceof TucanoModels.Asset && asset.getVodData()) {
        metadata = asset.getVodData();
      }
      await this.setState({ item: metadata });
    }
  }
  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  setShowModal(value) {
    this.setState({ showModal: value });
  }

  mouseEnter() {
    this.setState({ hover: this.props.infos, leavingModal: false });
  }

  mouseLeave() {
    this.setState({ hover: false, showModal: false, leavingModal: true });
    setTimeout(() => {
      this.setState({ leavingModal: false });
    }, 500);
  }

  render() {
    const {
      asset,
      rootClassName,
      size,
      grid,
      onlyPlay,
      large,
      fta,
      isConnected,
      isSlider,
      order,
      activeTrailer,
      isLocked,
      withoutProgress,
    } = this.props;
    const { hover, showModal, leavingModal } = this.state;
    let url, type;
    if (asset) {
      url = consts.routes.movieAsset.url.replace(":id", asset.getId());
    }
    /*let catalog;
    if (asset instanceof TucanoModels.TVShow) {
      catalog = asset.getCatalogue();
    }
    if (asset.catalogs) {
      catalog = asset.catalogs;
    }*/
    if (
      asset instanceof TucanoModels.Asset &&
      !asset.isCatchup() &&
      !asset.getCatchupId()
    ) {
      if (asset.isTVShow()) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getParentId());
      } else if (asset.isAssetTVShowEpisode === true) {
        url = consts.routes.player.url.replace(":assetId", asset.getId());
      } else if (asset.isCategory) {
        url = consts.routes.vod.detailUrl.replace(":id", asset.getId());
      }
    }

    if (asset instanceof TucanoModels.Channel) {
      url = consts.routes.playerlive.url.replace(":channelId", asset.getId());
    }

    if (asset.getType() === "EPGBroadcast") {
      type = "epgEvent";
      if (asset.isLive()) {
        url = consts.routes.playerlive.url.replace(
          ":channelId",
          asset.getChannelId()
        );
      } else {
        url = consts.routes.epgAsset.url.replace(":id", asset.getEpgId());
      }
    }

    if (asset.getType() === "link") {
      url = getRealSlug(asset.getUrl());
    }

    let stl;
    if (showModal && isSlider && order === 1) {
      stl = {
        top: "0px",
        minWidth: large ? size.posterLarge.width : size.poster.width,
        minHeight: large ? size.posterLarge.height : size.poster.height,
      };
    } else if (showModal && isSlider && order !== 1) {
      stl = {
        marginLeft: "-40px",
        top: "0px",
        minWidth: large ? size.posterLarge.width : size.poster.width,
        minHeight: large ? size.posterLarge.height : size.poster.height,
      };
    } else {
      stl = {
        minWidth: large ? size.posterLarge.width : size.poster.width,
        minHeight: large ? size.posterLarge.height : size.poster.height,
      };
    }
    // apply border radius && shadow
    const borderRadiusStyle =
      (consts.thumbnailBorderRadius &&
        consts.thumbnailStyle.length > 0 &&
        style[
          consts.thumbnailStyle[0][consts.thumbnailBorderRadius.toLowerCase()]
        ]) ||
      "";
    const shadowStyle =
      (consts.thumbnailShadow &&
        consts.thumbnailStyle.length > 0 &&
        style[
          consts.thumbnailStyle[1][consts.thumbnailShadow.toLowerCase()]
        ]) ||
      "";

    return (
      <Fragment>
        <LinkDuo
          to={url}
          className={classnames(rootClassName, style.container, {
            [style.container_grid]: !showModal && grid,
            // [borderRadiusStyle]: true,
            [shadowStyle]: true,
            [style.container_zoomIn]:
              !showModal && hover && consts.trailerOnFocus,
            [style.container_zoomOut]: leavingModal && consts.trailerOnFocus,
            [style.container_hover_first]:
              showModal && this.props.order === 1 && hover,
            [style.container_hover]:
              showModal && this.props.order !== 1 && hover,
          })}
          onMouseEnter={this.mouseEnter.bind(this)}
          onMouseLeave={this.mouseLeave.bind(this)}
          style={stl}
        >
          <Fragment>
            {!showModal ? (
              <>
                <div className={style.content}>
                  <Image
                    rootClassName={classnames({
                      [borderRadiusStyle]: true,
                    })}
                    src={asset.getImage(consts.image_hostname, {
                      width: 266,
                      height: 399,
                      languageId: getFromLocal("languageId"),
                    })}
                    width={large ? size.posterLarge.width : size.poster.width}
                    height={
                      large ? size.posterLarge.height : size.poster.height
                    }
                    ratio={ImageRatio.POSTER}
                    placeholder={PlaceholderType.PRIMARY}
                  />
                  {type === "epgEvent" && (
                    <Image
                      rootClassName={style.channel}
                      height={40}
                      width={40}
                      src={`${consts.image_hostname}${routes.imgdata}?type=${
                        consts.imageTypes.tvChannel
                      }&objectId=${asset.getChannelId()}&format_w=80&format_h=80&languageId=${getFromLocal(
                        "languageId"
                      )}`}
                      placeholder={PlaceholderType.TRANSPARENT}
                    />
                  )}
                  {asset && asset.flyImage && (
                    <div className={classnames(style.VODLogo)}>
                      <LogoVod
                        className={classnames(style.vodData)}
                        type={asset.flyImage}
                        size={40}
                      />
                    </div>
                  )}
                  {fta && !isConnected && (
                    <div className={style.ftaIconContainer}>
                      <FreeToAir className={style.fta} />
                    </div>
                  )}
                  {!withoutProgress &&
                    asset instanceof TucanoModels.Asset &&
                    !!asset.getSeekTime() &&
                    (large ? (
                      <ProgressBar
                        position={asset.getProgress()}
                        rootClassName={style.progressBarForXL}
                      />
                    ) : (
                      <ProgressBar
                        position={asset.getProgress()}
                        rootClassName={style.progressBar}
                      />
                    ))}
                  {!withoutProgress &&
                    type === "epgEvent" &&
                    asset.isLive() &&
                    asset.getEpgProgress() &&
                    (large ? (
                      <ProgressBar
                        position={asset.getEpgProgress()}
                        rootClassName={style.progressBarForXL}
                      />
                    ) : (
                      <ProgressBar
                        position={asset.getEpgProgress()}
                        rootClassName={style.progressBar}
                      />
                    ))}
                </div>
              </>
            ) : (
              <>
                {asset && asset.flyImage && (
                  <div className={classnames(style.VODLogo)}>
                    <LogoVod
                      className={classnames(style.vodData)}
                      type={asset.flyImage}
                      size={40}
                    />
                  </div>
                )}
                {fta && !isConnected && (
                  <div className={style.ftaIconContainer}>
                    <FreeToAir className={style.fta} />
                  </div>
                )}
                {!withoutProgress &&
                  asset instanceof TucanoModels.Asset &&
                  !!asset.getSeekTime() &&
                  (large ? (
                    <ProgressBar
                      position={asset.getProgress()}
                      rootClassName={style.progressBarForXL}
                    />
                  ) : (
                    <ProgressBar
                      position={asset.getProgress()}
                      rootClassName={style.progressBar}
                    />
                  ))}
                {!withoutProgress &&
                  type === "epgEvent" &&
                  asset.isLive() &&
                  asset.getEpgProgress() &&
                  (large ? (
                    <ProgressBar
                      position={asset.getEpgProgress()}
                      rootClassName={style.progressBarForXL}
                    />
                  ) : (
                    <ProgressBar
                      position={asset.getEpgProgress()}
                      rootClassName={style.progressBar}
                    />
                  ))}
              </>
            )}
          </Fragment>
          <ThumbnailInfos
            isTrailerActive={
              consts.trailerOnFocus &&
              activeTrailer &&
              (asset.type === "asset_vod-poster" ||
                asset.type === "asset_vod-background")
            }
            isPoster={true}
            setShowModal={this.setShowModal.bind(this)}
            show={hover}
            model={asset}
            onlyPlay={onlyPlay}
            subscriptionModal={this.subscriptionModal.bind(this)}
            isLocked={isLocked}
          />
        </LinkDuo>
      </Fragment>
    );
  }
}

ThumbnailPoster.defaultProps = {
  asset: {},
  rootClassName: "",
  size: {},
  grid: false,
  infos: true,
  onlyPlay: false,
  isLocked: false,
  withoutProgress: false,
};

ThumbnailPoster.propTypes = {
  asset: PropTypes.object,
  rootClassName: PropTypes.string,
  size: PropTypes.object,
  grid: PropTypes.bool,
  infos: PropTypes.bool,
  onlyPlay: PropTypes.bool,
};
