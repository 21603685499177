import classnames from "classnames";
import FreeToAir from "components/Icons/FreeToAir";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import { LinkDuo } from "components/LinkDuo";
import LogoVod from "components/LogoVod";
import ProgressBar from "components/ProgressBar";
import ThumbnailInfos from "components/ThumbnailInfos";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { getRealSlug } from "helpers/url";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { TucanoModels } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

export default class ThumbnailTotem extends Component {
  state = {
    hover: false,
    item: null,
  };
  async componentDidMount() {
    if (consts.displayVodData) {
      const { asset } = this.props;
      let metadata;
      if (asset instanceof TucanoModels.Asset && asset.getVodData()) {
        metadata = asset.getVodData();
      }
      await this.setState({ item: metadata });
    }
  }
  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const { asset, rootClassName, grid, onlyPlay, size, fta, isConnected } =
      this.props;
    const { hover /*, item*/ } = this.state;
    let url, type;
    /*let catalog;
    if (asset instanceof TucanoModels.TVShow) {
      catalog = asset.getCatalogue();
    }
    if (asset.catalogs) {
      catalog = asset.catalogs;
    }*/
    if (asset) {
      url = consts.routes.movieAsset.url.replace(":id", asset.getId());
    }
    if (asset instanceof TucanoModels.Asset && !asset?.isCatchup()) {
      if (asset.isTVShow() || asset.isAssetTVShowEpisode === true) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getParentId());
      } else if (asset.isCategory) {
        url = consts.routes.vod.detailUrl.replace(":id", asset.getId());
      }
    }
    if (asset instanceof TucanoModels.Channel) {
      url = consts.routes.playerlive.url.replace(":channelId", asset.id);
    }

    if (asset.getType() === "EPGBroadcast") {
      type = "epgEvent";
      if (asset.isLive()) {
        url = consts.routes.playerlive.url.replace(
          ":channelId",
          asset.getChannelId()
        );
      } else {
        url = consts.routes.epgAsset.url.replace(":id", asset.getEpgId());
      }
    }

    if (asset.getType() === "link") {
      url = getRealSlug(asset.getUrl());
    }

    // apply border radius && shadow
    const borderRadiusStyle =
      (consts.thumbnailBorderRadius &&
        consts.thumbnailStyle.length > 0 &&
        style[
          consts.thumbnailStyle[0][consts.thumbnailBorderRadius.toLowerCase()]
        ]) ||
      "";
    const shadowStyle =
      (consts.thumbnailShadow &&
        consts.thumbnailStyle.length > 0 &&
        style[
          consts.thumbnailStyle[1][consts.thumbnailShadow.toLowerCase()]
        ]) ||
      "";

    return (
      <LinkDuo
        to={url}
        className={classnames(rootClassName, style.container, {
          [style.grid]: grid,
          [borderRadiusStyle]: true,
          [shadowStyle]: true,
          [style.container_hover]: hover,
        })}
        onMouseEnter={() =>
          this.setState({ hover: this.props.showInfosOverlay })
        }
        onMouseLeave={() => this.setState({ hover: false })}
        style={{
          minWidth: size.totem.width,
          minHeight: size.totem.height,
        }}
      >
        <div className={style.content}>
          <Image
            src={asset.getImage(consts.image_hostname, {
              width: 266,
              height: 532,
              languageId: getFromLocal("languageId"),
            })}
            width={size.totem.width}
            height={size.totem.height}
            ratio={ImageRatio.TOTEM}
            placeholder={PlaceholderType.PRIMARY}
            rootClassName={borderRadiusStyle}
          />
          {type === "epgEvent" && (
            <Image
              rootClassName={style.channel}
              height={40}
              width={40}
              src={`${consts.image_hostname}${routes.imgdata}?type=${
                consts.imageTypes.tvChannel
              }&objectId=${asset.getChannelId()}&format_w=80&format_h=80&languageId=${getFromLocal(
                "languageId"
              )}`}
              placeholder={PlaceholderType.TRANSPARENT}
            />
          )}
          {asset instanceof TucanoModels.Asset && !!asset.getSeekTime() && (
            <ProgressBar
              position={asset.getProgress()}
              rootClassName={style.progressBar}
            />
          )}
          {type === "epgEvent" && asset.isLive() && asset.getEpgProgress() && (
            <ProgressBar
              position={asset.getEpgProgress()}
              rootClassName={style.progressBar}
            />
          )}
          {asset && asset.flyImage && (
            <div className={classnames(style.VODLogo)}>
              <LogoVod
                className={classnames(style.vodData)}
                type={asset.flyImage}
                size={40}
              />
            </div>
          )}

          {fta && !isConnected && (
            <div className={style.ftaIconContainer}>
              <FreeToAir className={style.fta} />
            </div>
          )}
        </div>
        <ThumbnailInfos
          show={hover}
          model={asset}
          onlyPlay={onlyPlay}
          subscriptionModal={this.subscriptionModal.bind(this)}
        />
      </LinkDuo>
    );
  }
}

ThumbnailTotem.defaultProps = {
  asset: {},
  rootClassName: "",
  size: {},
  showInfosOverlay: true,
  onlyPlay: false,
  large: false,
  grid: false,
};

ThumbnailTotem.propTypes = {
  asset: PropTypes.object,
  rootClassName: PropTypes.string,
  size: PropTypes.object,
  showInfosOverlay: PropTypes.bool,
  onlyPlay: PropTypes.bool,
  large: PropTypes.bool,
  grid: PropTypes.bool,
};
